import React from 'react';
import Page from '../components/Page';
import "./FCOI.scss";


const FCOI = ({}) => {

  return (
    <Page title="Dynamoid FCOI" header>
      <div className="fcoi">
        <iframe
          
          src="https://docs.google.com/document/d/e/2PACX-1vRuQBbIOk25-OQRygv_X4BBun8lD-DTliQgGPtLlDQGcoJDtLr3OXK1cXUfIkoyz8TqFqU468g3J2ZQ/pub?embedded=true"
          width="100%"
          height="1200"
        ></iframe>
      </div>
    </Page>
  );

}

export default FCOI;