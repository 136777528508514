import React, { useEffect, useState } from "react";
import "./Post.scss";
import Markdown from 'markdown-to-jsx';
import { labelToSlug } from '../utility';


const Post = ({ post }) => {

  const [blogText, setBlogText] = useState("Loading");



  useEffect(() => {
    console.log(post, 'fetching')
    const url = `/assets/blog/${labelToSlug(post.title)}.md`;
    console.log(url);
    if (post.title) {
      fetch(url).then(response => response.text()).then(text => { console.log("RETURNING" + text); setBlogText(text) })
    }
  }, [])

  return (
    <div className="post blog">
      <h1>{post.title}</h1>
      <h3>{post.author}</h3>
      <h5>{post.date}</h5>

      <Markdown >{blogText}</Markdown>
    </div>
  );

}

export default Post;