export const Content = {
  name: "Dynamoid",
  logo: "logo.png",
  blurb: "Understanding reality",
  description: "",
  splash: "10k2.jpg",
  sections: [
    {
      splash: true,
      background: "10k2.png",
      description: "Understanding reality",
    },

    {
      preface: "We are reinventing science education",
      link: "https://10k.science",
      image: "10k_science_logo.png",
      heading: "10k Science features",
      items: [
        {
          label: "Immersive experiences",
          description:
            "10k content aligns with hard-to-teach science topics and increases student focus and engagement. In one experience, users zoom into a human body down into the nucleus, interacting with DNA to explore the molecular mechanics of CRISPR gene editing",

          image: "10k_logo.png",
        },
        {
          label: "AI assessment",
          description:
            "We answer the question, “But are they thinking?” giving insight into the student thought process using in-app AI 'Guides' and a web portal for tracking student progress.",
          image: "10k_logo.png",
        },
        {
          label: "Authentic data",
          description:
            "VR experiences sourced from research from universities such as UC Berkeley, UC Davis, and the University of Washington.",

          image: "10k_logo.png",
        },
        {
          label: "NGSS alignment",
          description:
            "NGSS alignment by the learning science team at the Lawrence Hall of Science, creators of the leading curriculum, Amplify Science. ",
          image: "10k_logo.png",
        },
      ],

      tags: [
        {
          label: "Learn more",
          url: "https://10k.science",
        },
        {
          label: "AI Guides demo",
          url: "https://youtu.be/mlWki9kwsdc",
        },
      ],
      video: "10k_science_trailer.mp4",
    },
    {
      preface: "Learning with true understanding",
      heading: "Powers of Minus Ten is",
      items: [
        {
          label: "Award-winning",
          description:
            "Our app, Powers of Minus Ten - as seen in Science Magazine and Popular Mechanics - was featured by Apple in 'New and Noteworthy' and was part of the annual App Store Rewind. In 2012, POMT was awarded 'Best Serious Game' from Unity Technologies and was broadcast internationally in the iPad commercial 'Alive'.",
          image: "pomt1.png",
        },
        {
          label: "Engaging",
          description:
            "Originally developed with a grant from the National Science Foundation, Powers of Minus Ten lets you zoom into a human hand down to the molecular level of magnification, exploring the myriad structures within.",
          image: "pomt1.png",
        },
        {
          label: "Groundbreaking",
          description:
            "Powers of Minus Ten was designed to facilitate true mastery (learning with understanding) of some of the most crucial, traditionally-difficult core scientific concepts and processes by visually presenting structures in their environmental context and using game mechanics as motivators. ",
          image: "pomt3.png",
        },
      ],
      tags: [
        {
          label: "POMT website",
          url: "http://powersofminusten.com",
        },
        {
          label: "Get POMT for iOS",
          url: "https://itunes.apple.com/us/app/powers-of-minus-ten/id429123553?mt=8",
        },
      ],
      video: "HomePage.mp4",
      background: null,
    },
    {
      preface: "Our technology",

      heading: "We build for",
      items: [
        {
          label: "XR",
          description:
            "We've been developing 3D-interactive applications since 2010, and designing for immersive environments - beginning with planetarium movies - for even longer.",
        },
        {
          label: "The Web",
          description:
            "We create all kinds of web applications, as well as work with standard web technology such as React.js.",
        },
        {
          label: "iOS/Android",
          description: "We've created award-winning apps for mobile devices.",
        },
      ],

      video: "Development.mp4",
      background: null,
    },
  ],
};

export const BlogContent = {
  title: "Dynamoid Blog",
  description: "Things of note going on at Dynamoid or something",
  authors: [
    {
      name: "Laura Lynn Gonzalez",
      about: "",
    },
  ],
  posts: [
    {
      title: "10k Science Launch",
      date: "12-20-2023",
      author: "Laura Lynn Gonzalez",
    },
    {
      title: "CRISPR VR",
      date: "6-17-2019",
      author: "Laura Lynn Gonzalez",
    },
    {
      title: "10k launch",
      date: "5-28-2018",
      author: "Laura Lynn Gonzalez",
    },
    {
      title: "Why I'm giving my award-winning app away for free",
      date: "3-25-2018",
      author: "Laura Lynn Gonzalez",
    },
    {
      title: "Is VR for real this time?",
      date: "6-15-2016",
      author: "Dan Gonzalez",
    },
  ],
};
