import React, { useEffect, useState } from 'react';

import { BlogContent } from '../Content';
import Post from '../components/Post';
import PostCard from '../components/PostCard';
import "./Blog.scss";
import Page from '../components/Page';


export default class Blog extends React.Component{
	
	posts(){
		console.log(BlogContent)
		return BlogContent.posts.map((post, i) =>
			 <PostCard key={i} post={post}/>
		)
	}

	render(){
		return(
			<Page title="Dynamoid Blog" header>
				<div className="blog">
					<h1>Dynamoid Blog</h1>
					{this.posts()}
				</div>
			</Page>
		
		)
	}
}





