import React from 'react';
import Page from '../components/Page';


const ScienceEducatorReport = ({}) => {

  return(
    <Page title="2023 Science educator report" header>
      <div className='blog'>
        
        <iframe src="https://docs.google.com/document/d/e/2PACX-1vQP0unZfTJ5MDBoe2kvx0ymy3YDJaUhuds7780xLTRUDk8vEHkXa-4KQXWS_COg54nuQxH7r27NQLGe/pub?embedded=true" width="100%" height="2200" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
     
    </Page>
  )

}

export default ScienceEducatorReport;