import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Content, BlogContent } from '../Content';
import './Main.scss'


export default class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentSection: Content.sections[0],
      scrolling: false,
      sections: Content.sections,
      sectionPosition: 0,
      time: 0,
      timer: null,
      header: false
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollTimeout = this.scrollTimeout.bind(this);
    this.animateScroll = this.animateScroll.bind(this);

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }

  handleScroll(e) {
    if (window.scrollY > 300 && !this.state.header) {
      this.setState({ header: true });
    }
    else if (window.scrollY < 300 && this.state.header) {
      this.setState({ header: false });
    }

    const sectionHeight = Math.round((document.body.scrollHeight - window.innerHeight) / (this.state.sections.length));

    // console.log(window.scrollY);

    let scroll = (window.scrollY - 300);
    if (scroll < 0) {
      scroll = 0;
    }
    const sectionNumber = Math.round(scroll / sectionHeight);


    const section = this.state.sections[sectionNumber];
    if (sectionNumber < this.state.sections.length) {
      this.changeSection(section, sectionNumber * sectionHeight);
    }


  }



  changeSection(section, position) {
    if (!this.checkIfCurrent(section)) {

      clearInterval(this.state.timer);

      this.setState({
        currentSection: section,
        timer: setInterval(this.scrollTimeout, 1),
        sectionPosition: position
      });
    }
  }

  scrollTimeout() {

    var time = 2 + this.state.time;

    if (time > 150) {
      clearInterval(this.state.timer);
      // this.setState({
      //     time:0,
      //     timer: setInterval(this.animateScroll, 0.01)
      // })


    }
    else {
      this.setState({
        time: time
      })
    }

    // console.log("Scroll timer ");
  }

  animateScroll() {
    // console.log("Section position: " + this.state.sectionPosition);
    // console.log("Scroll Y: " + window.scrollY);
    if (this.state.sectionPosition > window.scrollY) {
      window.scrollTo(0, window.scrollY + 1);
    }
    else if (this.state.sectionPosition < window.scrollY) {
      window.scrollTo(0, window.scrollY - 1);
    }
    else {
      clearInterval(this.state.timer);
    }
  }

  checkIfCurrent(section) {
    return this.state.currentSection == section;
  }

  getDisplayState(section) {
    return this.checkIfCurrent(section) ? "visible" : "hidden";
  }

  render() {
    const sections = Content.sections.map((section, i) =>
      <Section key={i} index={i} heading={section.heading} display={this.getDisplayState(section)} />
    );

    return (
      <div className="main">
        <div className="pre-header"></div>
        <Header name={Content.name} showing={this.state.header} />

        {sections}
        <Logos />
        <Footer />
      </div>
    );
  }

}

class Logos extends React.Component {
  render() {
    return (
      <div className="logos">
        <h2>Clients and collaborators</h2>
        <div className="logo-display">
          <img src="assets/images/logos/wiley_logo.png" />
          <img src="assets/images/logos/vulcan_logo.png" />
          <img src="assets/images/logos/bmnt.png" />
          <img src="assets/images/logos/hall-logo-reverse.png" />
          <img src="assets/images/logos/igi_logo.png" />
          <img src="assets/images/logos/hf.png" />
          <img src="assets/images/logos/spectrum_logo.png" />
          <img src="assets/images/logos/UW_logo.png" />
          <img src="assets/images/logos/duquesne_logo.png" />
          <img src="assets/images/logos/UCBerkeley.png" />
        </div>

      </div>
    )
  }
}

function Splash(props) {
  return (
    <div className={"splash "}>

      <img src={"./assets/images/" + Content.logo} />
      <h2 dangerouslySetInnerHTML={{ __html: props.description }}></h2>
      {/* <button>Go</button> */}
    </div>
  )
}


class Section extends React.Component {
  constructor(props) {
    super(props);
    const section = Content && Content.sections[this.props.index];
    this.state = {
      section: section,
      currentItem: 0,//default item is first
      timer: null,
      time: 0
    };
    this.incrementItem = this.incrementItem.bind(this);
  }
  componentDidMount() {
    // this.startAutoPlay();
  }
  componentWillUnmount() {
    // this.stopAutoPlay();

  }
  startAutoPlay() {
    this.setState({
      timer: setInterval(this.incrementItem, 15000)
    })
  }

  stopAutoPlay() {
    clearInterval(this.state.timer);
  }

  incrementItem() {
    if (this.state.currentItem == this.state.section.items.length - 1) {
      this.changeActiveItem(0);
    }
    else {
      this.changeActiveItem(this.state.currentItem + 1);
    }

  }

  itemButtonClicked(item) {
    this.stopAutoPlay();
    if (!this.checkIfCurrent(item)) {

      this.changeActiveItem(item);
      console.log("Clicked new item: " + item.label);
    }
  }
  changeActiveItem(item) {
    this.setState({
      currentItem: item,
    });
  }

  getItems() {
    return this.state.section.items.map((item, i) =>

      <Item key={i} className="item" label={item.label} description={item.description} display={this.itemDisplayState(i)} image={item.image} preface={item.preface} onClick={() => this.itemClicked(i)} />

    );
  }

  getItemButtons() {
    return this.state.section.items.map((item, i) =>
      <ItemButton key={i} className="item-button" label={item.label} display={this.itemButtonDisplayState(i)} onClick={() => this.itemButtonClicked(i)} />
    );
  }

  getLinks() {

    if (this.state.section.tags) {
      return this.state.section.tags.map((tag, i) =>
        <LinkButton key={i} label={tag.label} url={tag.url} />
      )
    }
    else {
      return "";
    }

  }

  checkIfCurrent(item) {
    return item == this.state.currentItem;
  }

  itemDisplayState(item) {
    return this.checkIfCurrent(item) ? "visible" : "hidden";
  }

  itemButtonDisplayState(item) {
    return this.checkIfCurrent(item) ? "current" : "inactive";
  }

  getPreface() {
    let preface = this.state.section.preface;
    let image = this.state.section.image;
    let link = this.state.section.link;
    // console.log(preface);
    return preface ? (
      <div className="preface">
        {preface}
        <div>
        {/* {image && <img width="300" src={`assets/images/${image}`} />} */}
        {link && <button onClick={() => window.location = link } >Learn more</button>}
        </div>
      </div>
    ) : (
      ""
    );
  }


  render() {
    if (this.state.section.splash) {
      // console.log("IS SPLASH");
      return (
        <div>
          <Background display={this.props.display} video={this.state.section.video} image={this.state.section.background} />
          <Splash description={this.state.section.description} />
        </div>
      )
    }


    const sectionClasses = "section " + this.props.display;

    let prefix = this.state.section.heading;
    let label = this.state.section.items[this.state.currentItem].label;

    if (isVowel(label.charAt(0)) && prefix.charAt(prefix.length - 1) == 'a') {
      prefix = prefix + "n";
    }


    else {
      return (
        <div>
          <Background display={this.props.display} video={this.state.section.video} image={this.state.section.background} />

          <div className="section current">

            {this.getPreface()}

            <div className="label-prefix"><h2>{prefix}</h2></div>
            {this.getItemButtons()}
            <div className="section-content">




              <div>

                {this.getItems()}
              </div>

              <div className="links">
                {this.getLinks()}
              </div>



            </div>


          </div>
        </div>

      );
    }

  }
}

function isVowel(letter) {
  return (letter == "A" || letter == "E" || letter == "I" || letter == "O" || letter == "U");
}

class ItemButton extends React.Component {
  render() {
    return (
      <div className={"label " + this.props.display} onClick={() => this.props.onClick()}><h2>{this.props.label}</h2></div>
    );
  }

}

class Item extends React.Component {


  render() {
    return (
      <div className={"item " + this.props.display}>
        {this.props.image ? <img src={"assets/images/" + this.props.image} /> : ""}
        <div className={"description " + this.props.display}>{this.props.description}</div>
      </div>

    );
  }
}


function LinkButton(props) {
  return (
    <div className="link" >
      <a href={props.url} target="_blank">{props.label}</a>
    </div>
  )
}



function VideoBackground(props) {
  const videoURL = './assets/videos/' + props.video;
  return (
    <div className={"fullscreen-background " + props.display}>
      <video loop muted autoPlay className="video">
        <source src={videoURL} type="video/mp4" />
      </video>
    </div>
  );
}

function ImageBackground(props) {
  const imageURL = './assets/images/' + props.image;
  return (
    <div className={"fullscreen-background " + props.display}>
      <img src={imageURL} />
    </div>
  );
}

class Background extends React.Component {
  render() {
    if (this.props.video) {
      return (
        <VideoBackground display={this.props.display} video={this.props.video} />
      );
    }
    else if (this.props.image) {
      return (
        <ImageBackground display={this.props.display} image={this.props.image} />
      );
    }
    else {
      return (
        <div className="fullscreen-background">Missing background!</div>
      );
    }
  }
}
