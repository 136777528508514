import React, { useEffect, useState } from "react";
import "./PostCard.scss";
import Markdown from 'markdown-to-jsx';
import { labelToSlug } from '../utility';
import { Link, useNavigate } from "react-router-dom";


const PostCard = ({ post }) => {

  const [blogText, setBlogText] = useState("Loading");

  const navigate = useNavigate();

  useEffect(() => {
    const url = `assets/blog/${labelToSlug(post.title)}.md`;
    if (post.title) {
      fetch(url).then(response => response.text()).then(text => { console.log("RETURNING" + text); setBlogText(text) })
    }
  }, [])

  return (
    
      <div className="post-card" onClick={() => navigate(`/blog/${labelToSlug(post.title)}`)}>
        <h1>{post.title}</h1>
        <h3>{post.author}</h3>
        {/* <h5>{post.date}</h5> */}

        <Markdown >{`${blogText.substring(0, 250)}...`}</Markdown>
      </div>
  
    
  );

}

export default PostCard;