import React from 'react';
import { Content } from './Content'


export default function Header(props) {
	return (
		<div className={"header" + (props.showing ? "" : " disabled")}>
			{Content && Content.logo ? <a href="/"><img src={"../assets/images/" + Content.logo} /></a> : <a href="/"><h1>{props.name}</h1></a>}
			<h1>{Content && Content.blurb}</h1>
			<Social />
		
		</div>
	)
}




function Social(props){
	return(
		<div className="social">
			{/* <a href="/blog/">
				blog
			</a> */}
			<a href="https://twitter.com/Dynamoid" target="blank">
				<svg viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
					<path fill="#000" d="M18.526149,2.84573162 C19.4160944,2.28412538 20.0989611,1.39361548 20.41944,0.332803698 C19.5861948,0.85410949 18.6654341,1.23111368 17.6830428,1.43521595 C16.899102,0.551206125 15.7786582,0 14.5386512,0 C12.1597112,0 10.231907,2.03452261 10.231907,4.54355048 C10.231907,4.89975444 10.2676528,5.2468583 10.3416094,5.57836198 C6.762106,5.38855987 3.58813171,3.58153979 1.46187709,0.83070923 C1.09086107,1.50411671 0.878851911,2.28412538 0.878851911,3.11613462 C0.878851911,4.69175213 1.63937313,6.08276759 2.7955626,6.89917666 C2.08927628,6.8757764 1.42489875,6.6690741 0.843106181,6.33107035 L0.843106181,6.38697097 C0.843106181,8.58919544 2.32717028,10.4261158 4.30058109,10.8421205 C3.93819334,10.9487217 3.55854904,11.0020222 3.1641134,11.0020222 C2.88677584,11.0020222 2.61560134,10.9747219 2.35428773,10.9214213 C2.90156718,12.7258414 4.49286846,14.041456 6.37876387,14.0765564 C4.90332805,15.29597 3.04578271,16.021378 1.02799789,16.021378 C0.680401479,16.021378 0.336502905,16.0018778 0,15.9589773 C1.9068498,17.2472916 4.17115689,18 6.60433175,18 C14.5300229,18 18.8614193,11.0761231 18.8614193,5.07135635 C18.8614193,4.87375415 18.858954,4.67745197 18.8503258,4.48374982 C19.6921993,3.8428427 20.4243705,3.0420338 21,2.13072367 C20.2271527,2.49212769 19.3963726,2.73653041 18.526149,2.84573162 Z"></path>
				</svg>
			</a>
			<a href="https://www.linkedin.com/company/dynamoid" target="blank">
				<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<path fill="#000" d="M0.504468485,6.41311307 L4.40263405,6.41311307 L4.40263405,19.9987194 L0.504468485,19.9987194 L0.504468485,6.41311307 Z M2.35418627,4.71379178 L2.32596425,4.71379178 C0.914863594,4.71379178 0,3.67524651 0,2.36009732 C0,1.01805609 0.94190969,0 2.38123236,0 C3.81937912,0 4.70366886,1.01549494 4.73189087,2.3562556 C4.73189087,3.67140479 3.81937912,4.71379178 2.35418627,4.71379178 L2.35418627,4.71379178 Z M20,20 L15.5797272,20 L15.5797272,12.9683698 C15.5797272,11.1281854 14.8882879,9.8732232 13.3678269,9.8732232 C12.2048448,9.8732232 11.5580903,10.7196824 11.2570555,11.537969 C11.1441675,11.8299398 11.1618062,12.2384428 11.1618062,12.6482264 L11.1618062,20 L6.7826905,20 C6.7826905,20 6.83913452,7.54514022 6.7826905,6.41311307 L11.1618062,6.41311307 L11.1618062,8.54526828 C11.420508,7.61301063 12.8198495,6.28249456 15.0529163,6.28249456 C17.8233772,6.28249456 20,8.23793059 20,12.4446152 L20,20 L20,20 Z"></path>
				</svg>
		</a>

		<a href="mailto:info@dynamoid.com">
				<svg version="1.1" id="Capa_1"  x="0px" y="0px"
	 viewBox="0 0 485.211 485.211" >

	<path d="M301.393,241.631L464.866,424.56H20.332l163.474-182.928l58.801,51.443L301.393,241.631z M462.174,60.651H23.027
		l219.579,192.142L462.174,60.651z M324.225,221.67l160.986,180.151V80.792L324.225,221.67z M0,80.792v321.029L160.972,221.64
		L0,80.792z"/>

</svg>
		</a>
		
	
			
		
		</div>
	)
}
