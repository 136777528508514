import React from 'react';

export default function Footer(props) {
	return (
    <div className={"footer"}>
      <div className="left-footer">
        <h2>© {new Date().getFullYear()} Dynamoid, Inc</h2>
        <a href="/fcoi">FCOI policy</a>
      </div>

      <div>
        {/* <Sun /> */}
        <h2>Oakland, CA</h2>
      </div>

      <div className="contact">
        <h2>510-646-1304</h2>
        <h2>info@dynamoid.com</h2>
      </div>
    </div>
  );
}


function Sun(props){
	return(
		<svg version="1.1"xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 100 100"   >

			<path d="M92,54c-4,4-9,4-13,7c3,3,6,6,9,9c-5-1-10,2-15,1c1,4,2,8,4,12c-4-2-10-3-14-5c1,4-2,8,0,12c-4-3-8-6-12-9
	c-3,3-2,8-5,11c-2-4-4-9-6-13c-4,1-7,5-10,8c1-5-2-10-1-15c-3,3-8,2-11,5c0-5,3-9,4-14c-4-2-8-1-12-1c3-4,7-7,10-11c-4-2-8-4-12-5
	c4-3,9-5,14-7c-3-3-6-6-9-9c5,1,10-2,15-1c-1-4-3-8-5-12c5,3,10,2,15,5c0-4,0-8,0-12c4,3,8,5,11,9c2-3,4-7,5-11c4,4,4,9,7,13
	c4-2,6-5,9-8c2,5,1,10,1,15c4-3,8-2,12-5c-1,5-3,10-5,14c4,2,9,1,13,1c-2,3-4,5-7,7c-1,1-2,3-3,4C85,50,87,54,92,54L92,54z ">
				<animate 
				id="anim1" 
				attributeName="d"
				attributeType="XML"
				repeatCount="indefinite" 
				// begin="0s;anim2.end" 
			
				to=" M92,56c-4,3-9,4-14,7c2,4,5,6,8,9c-5,2-11,1-16,1c2,3,3,8,5,11c-5,0-10-3-15-5c0,4,0,8,0,12c-5-3-7-7-12-10
	c-3,3-3,7-4,11c-3-4-4-9-7-14c-4,2-6,5-9,8c-2-5-1-11-1-16c-3,2-8,3-11,5c0-5,3-10,5-15c-4,0-8,0-12,0c3-5,7-7,10-12c-3-3-7-3-11-4
	c4-3,9-4,14-7c-2-4-5-6-8-9c5-2,11-1,16-1c-2-3-3-8-5-11c5,0,10,3,15,5c0-4,0-8,0-12c5,3,7,7,12,10c3-3,3-7,4-11c3,4,4,9,7,14
	c4-2,6-5,9-8c2,5,1,11,1,16c3-2,8-3,11-5c0,5-3,10-5,15c4,0,8,0,12,0c-3,5-7,7-10,12C84,55,88,55,92,56L92,56z" 
				dur="2s"
				 />
				
	</path>
	</svg>

	)
}


