import React from 'react';
import Page from '../components/Page';


const Bootcamp = ({}) => {

  return(
    <Page title="Dynamoid Bootcamp" header>
      <div className='blog'>
        <h2>VR science communication bootcamp</h2>
        <p>Dynamoid is launching its inaugural science communication bootcamps in the Summer of 2022. The bootcamps are experiences designed for early-stage researchers (grad students, post-docs) in the sciences to work with virtual reality data visualization techniques to better communicate their research. </p>
        <h1><small>Scientists with complex or multiscale datasets are especially encouraged to apply!</small></h1>
        <h3>WHAT</h3>
        <ul>
          <li>Create VR experiences using your data</li>
          <li>20-40 hours over two weeks</li>
          <li>Use Dynamoid's <a href="https://10k.systems" target="_blank">VR data visualization platform, 10k</a></li>
          <li>Feedback and guidance from experts in scientific and communications fields</li>
          
        </ul>
        <h3>WHEN</h3>
        <ul>
          <li>First option <b>June 20 - July 1st</b></li>
          <li>Second option <b>July 11 - 22nd</b></li>
          <li>Day 1 is Kickoff Day</li>
          <li>Day 10 is Demo Day</li>
        </ul>

        <h3>WHERE</h3>
        <ul>
          <li>The bootcamp will take place at the Dynamoid offices in Oakland, CA</li>
          <li>Applicants able to be present in Oakland for at least some of the bootcamp would be ideal, but <b>participation can be fully remote if necessary</b></li>
          <li>US-based applicants only (Sorry! It's a condition of the grant)</li>
        </ul>

        <h3>WHY</h3>
        <ul>
          <li>At the end of the bootcamp, you will have a VR experience that puts your research in context</li>
          <li>Gain valuable experience learning from experts in science communications</li>
          <li>All participants will be provided with an Oculus Quest 2 headset</li>
          
        </ul>

        <h3>HOW</h3>
        <ul>
          <li>Funding is available for travel, equipment, and direct support on a case-by-case basis.</li>
          <li>Interested applicants should apply below or <a href="mailto:info@dynamoid.com">contact us</a> for more information</li>
        </ul>

        <p> <b>For US-based applicants only.</b></p>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScGX9omvU6aXBkZNPcun599DD1JFgqpx-pYbe05Wr1QjpwBRA/viewform?embedded=true" width="100%" height="2200" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
     
    </Page>
  )

}

export default Bootcamp;