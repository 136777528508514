"use strict";
import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter,  Routes, Route} from 'react-router-dom';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Bootcamp from './pages/Bootcamp';
import Main from './pages/Main';
import Study from './pages/Study';
import ScienceEducatorReport from './pages/ScienceEducatorReport';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyPartnership from './pages/PrivacyPolicyPartnership';
import FCOI from './pages/FCOI';





const App = () =>{

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/bootcamp" element={<Bootcamp />} />
        <Route
          path="/science-educator-report"
          element={<ScienceEducatorReport />}
        />
        <Route path="/study" element={<Study />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/fcoi" element={<FCOI />} />
        <Route
          path="/privacy/:appName"
          element={<PrivacyPolicyPartnership />}
        />
        <Route path="/blog/:postId" element={<BlogPost />} />
        <Route
          path="/Wiley/Tansey"
          element={() => (window.location = "http://tansey.dynamoid.com")}
        />
        <Route
          path="/Wiley/Pratt"
          element={() => (window.location = "http://pratt.dynamoid.com")}
        />
        <Route
          path="/Wiley/PrattAccessibility"
          element={() => (window.location = "http://pratt.dynamoid.com")}
        />

        <Route from="*" to="/" />
      </Routes>
    </BrowserRouter>
  );




}

export default App;
