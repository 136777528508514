import React from 'react';
import Page from '../components/Page';


const PrivacyPolicy = ({}) => {

  return (
    <Page title="Dynamoid/10k Science Privacy Policy" header>
      <div className="blog">
        <h1>Privacy Policy for 10k Science, provided by Dynamoid </h1>
        <h4>Last Updated: 5/12/23</h4>
        <p>
          1. Introduction Welcome to 10k Science, provided by Dynamoid ("we,"
          "our," or "us"). We are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy explains how we collect, use, and safeguard the data collected
          from users of our VR app.
        </p>
        <p>
          2. Information We Collect We collect basic analytic data, including
          the device identifier (Device ID), solely for the purpose of improving
          our app's functionality and user experience. This data does not
          include personally identifiable information.
        </p>
        <p>
          3. Use of Information The data we collect is used exclusively for the
          following purposes: App Improvement: We analyze the collected data to
          identify and rectify issues, optimize performance, and enhance the
          overall user experience.
        </p>
        <p>
          4. Data Security We take appropriate measures to protect the data we
          collect, including encryption and other security protocols, to prevent
          unauthorized access, disclosure, alteration, or destruction.
        </p>
        <p>
          5. User Rights As a user, you have the right to request access to,
          correction, or deletion of your data collected by our app. Please
          contact us at info@dynamoid.com to exercise these rights or for any
          privacy-related inquiries.
        </p>
        <p>
          6. Third-Party Services We do not share or sell user data with third
          parties. Our data collection and usage practices are solely for app
          improvement purposes, and no data is used for advertising or
          marketing.
        </p>
        <p>
          7. Age Restrictions Our app is not intended for use by children under
          13 years of age.
        </p>
        <p>
          8. Updates to this Privacy Policy We may update this Privacy Policy
          from time to time to reflect changes in our data practices. We will
          notify users of any significant changes via email. Please review this
          Privacy Policy periodically.
        </p>
        <p>
          9. Legal Compliance We strive to comply with all applicable data
          protection laws and regulations. If you have any concerns or questions
          about our data practices or this Privacy Policy, please contact us at
          info@dynamoid.com.
        </p>
        <p>
          10. Contact Us If you have any questions or concerns about our Privacy
          Policy or data practices, you can contact us at: info@dynamoid.com
        </p>
      </div>
    </Page>
  );

}

export default PrivacyPolicy;