import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';

import { Content } from '../Content';
import "./Page.scss";

const Page = ({ children, title, header }) => {
  const [headerVisible, setHeaderVisible] = useState(false);

  useEffect(() => {
    document.title = title;
  }, [])

  useEffect(() => {
    const handleScroll = (e) => {
      if (window.scrollY > 300 && !headerVisible) {
        setHeaderVisible(true);
      }
      else if (window.scrollY < 300 && headerVisible) {
        setHeaderVisible(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, [headerVisible])

 


  return(
    <div className="page">
      <div className="pre-header"></div>
      {children}
      <Header name={Content.name} showing={header ? header : headerVisible} />
      <Footer />
    </div>
  )
}

export default Page;