import React, { useEffect } from 'react';
import Page from '../components/Page';
import { useParams } from 'react-router';


const PrivacyPolicyPartnership = ({}) => {

  const appName = useParams().appName.replace("+", " ");



  return (
    <Page title={`${appName} Privacy Policy`} header>
      <div className="blog">
        <h1>Privacy Policy for {appName}, provided by Dynamoid </h1>
        <h4>Last Updated: 1/16/24</h4>
        <p>
          <b>1. Introduction.</b> Welcome to {appName}, provided by Dynamoid
          ("we," "our," or "us"). We are committed to protecting your privacy
          and ensuring the security of your personal information. This Privacy
          Policy explains how we collect, use, and safeguard the data collected
          from users of our app.
        </p>
        <p>
          <b>2. Information We Collect.</b> We collect basic analytic data,
          including the device identifier (Device ID), solely for the purpose of
          improving our app's functionality and user experience. This data does
          not include personally identifiable information.
        </p>
        <p>
          <b>3. Use of Information.</b> The data we collect is used exclusively
          for the following purposes: App Improvement: We analyze the collected
          data to identify and rectify issues, optimize performance, and enhance
          the overall user experience.
        </p>
        <p>
          <b>4. Data Security.</b> We take appropriate measures to protect the
          data we collect, including encryption and other security protocols, to
          prevent unauthorized access, disclosure, alteration, or destruction.
        </p>
        <p>
          <b>5. User Rights.</b> As a user, you have the right to request access
          to, correction, or deletion of your data collected by our app. Please
          contact us at info@dynamoid.com to exercise these rights or for any
          privacy-related inquiries.
        </p>
        <p>
          <b>6. Third-Party Services.</b> We do not share or sell user data with
          third parties. Our data collection and usage practices are solely for
          app improvement purposes, and no data is used for advertising or
          marketing.
        </p>
        <p>
          <b>8. Updates to this Privacy Policy.</b> We may update this Privacy
          Policy from time to time to reflect changes in our data practices. We
          will notify users of any significant changes via email. Please review
          this Privacy Policy periodically.
        </p>
        <p>
          <b>9. Legal Compliance.</b> We strive to comply with all applicable
          data protection laws and regulations. If you have any concerns or
          questions about our data practices or this Privacy Policy, please
          contact us at info@dynamoid.com.
        </p>
        <p>
          <b>10. Contact Us.</b> If you have any questions or concerns about our
          Privacy Policy or data practices, you can contact us at:
          info@dynamoid.com
        </p>
      </div>
    </Page>
  );

}

export default PrivacyPolicyPartnership;