import React, { useState } from 'react';
import Page from '../components/Page';


const Study = ({}) => {
  const [userType, setUserType] = useState("student");
  const [selectedUser, setSelectedUser] = useState(false);

  const handleUserSelection = (e) => {
    console.log(e.target.value);
    setUserType(e.target.value);
    e.preventDefault();
  }


  return(
    <Page title="Dynamoid 10k User Study" header>
      <div className='blog'>
        <p>
          Dynamoid has created a <a href="https://10k.systems" target="blank">program called 10k</a>. The program is a 3D-interactive Virtual Reality application for Oculus VR headsets. The program is designed to give students a tour of the human body and expose them to concepts in cell biology, genetics, and CRISPR gene editing technology.
          </p>
        <span className='logos'>
          <img src="./assets/images/logos/igi_logo.png" />
          <img src="./assets/images/logos/hall-logo-reverse.png" />
          <img src="./assets/images/logos/UCBerkeley.png" />
        </span>
       
        <p className='small'><em>This project is a partnership between Dynamoid, the Innovative Genomics Institute, and Lawrence Hall of Science at UC Berkeley. Research activities are supported by the National Institute Of General Medical Sciences of the National Institutes of Health under Award Number R43GM146471. The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health. </em></p>
        

        <h4>Are you a:</h4>
        <select value={userType} onChange={handleUserSelection}>
          <option selected={userType == "student"} value="student">Student</option>
          <option selected={userType == "parent"} value="parent">Parent or Guardian</option>
        </select>
        <br /><br />
        <button onClick={() => setSelectedUser(true)}>SUBMIT</button>

        {selectedUser &&
         <React.Fragment>
          
            <p>The evaluation of 10k will take place at one of the following:
              </p>
              <ul>
              <li>XR Marin regional training center (Novato, CA)</li>
              <li>Dynamoid offices (Oakland, CA)</li>
              <li>Lawrence Hall of Science (Berkeley CA)</li>
              </ul>
              <p>
               {userType == "student" ? "You" : "Your child"} may participate in any of the following activities:</p>
            <ul>
              <li>
                Survey on learning - Participants will complete a survey that assesses how much they think they learned and how their attitudes changed after using the VR software (15 minutes per survey)
              </li>
              <li>
                Usability testing - Participants will try out the 10k application and give their opinion about it. Researchers will also collect data on the computer about users’ actions such as hand and arm movements and the time it takes to complete certain activities.  This will take about 10-15 minutes to complete.
              </li>
              <li>
                Youth interview - Some participants will also be asked to participate in a short, 10-15 minute interview to share their reactions to the VR experience and its impact on them.
              </li>
            </ul>

            <p><b>Benefits.</b>{userType == "student" ? "You" : "Your child"} will get to try an innovative program that teaches complex biology concepts and communicates cutting-edge research. Furthermore, if {userType == "student" ? "you" : "your child"} has never experienced VR, this will be a chance to do so and explore its immersive world. We hope the feedback provided by participants will help improve 10k, which may someday be used by students across the country.</p>
            

            <p>

              <b>Risks.</b> Using Virtual Reality technology comes with some inherent risks including motion sickness. 
              
             
              </p>
            <ul>
              <li> Though Dynamoid has taken steps to minimize these risks, if {userType == "student" ? "you" : "your child"} {userType == "student" ? "have" : "has"} epilepsy, a history of seizures, or are prone to motion sickness, {userType == "student" ? "you" : "your child"} should not participate in this research. </li>
              <li> To learn more about safety information for Oculus headsets, visit the Oculus Safety Center.</li>
              <li> You will need to fill out <a href="https://calberkeley.ca1.qualtrics.com/jfe/form/SV_6zdO5ugId8yElfg" target="blank">this consent form</a> before {userType == "student" ? "you" : "your child"} can participate in the study. It needs to be signed by a parent or guardian if you are under 18. </li>
            </ul>
              <p>
                To sign up, enter your email address and study location below.
              

            </p>

            {userType == "student" ? 
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdBkG-I2dByjrN2pvMYbuzfo4ox0WCOfI6Hej2MAi3is-Nz_g/viewform?usp=sf_link" width="100%" height="1600" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            :
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSddedMruT4Syd3ysUZ3AVTyWvtGmhTGaTMWwiEg9RBmPCUPRQ/viewform?usp=sf_link" width="100%" height="1500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> 
              }

            




         </React.Fragment>
        
        
        }
      </div>
       
    </Page>
  )

}

export default Study;