import React from "react";
import { useParams } from "react-router";
import Page from "../components/Page";
import Post from "../components/Post";
import { BlogContent } from "../Content";
import { labelToSlug } from "../utility";


const BlogPost = ({ }) => {

  const postId = useParams().postId;

  console.log(postId);
  const post = BlogContent.posts.find(p => labelToSlug(p.title) === postId);



  return(
    <Page title={`Dynamoid Blog - ${post.title}`} header>
      <Post post={post} />
    </Page>
    
  )


}

export default BlogPost;