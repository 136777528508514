

export function labelToSlug(text){

    var textArray = text.toLowerCase().replace("?", "").split(" ");
    return textArray.join("-");
}
export function labelToImage(text){

    var textArray = text.toLowerCase().split(" ");
    return textArray.join("_");
}

////////
export function labelToFolder(text){

    return text.split(" ").join("").toLowerCase();
}

export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }




  return array;
}


export function fileExists(url) {
    if(url){
        var req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send();
        return req.status==200;
    } else {
        return false;
    }
}
